<script>
export default {
    data() {
        return {
        };
    },
    components: {
    },
    props: {
    },
};
</script>

<template>
    <section class="section p-5 bg-light mb-4">
        <div class="container">
            <div class="row text-center">
                <div class="col-lg-4">
                    <div class="process-card">
                        <div class="process-arrow-img d-none d-lg-block">
                            <img src="@/assets/images/process-arrow-img.png" alt="" class="img-fluid">
                        </div>
                        <div class="avatar-sm icon-effect mx-auto mb-4">
                            <div class="avatar-title bg-transparent text-primary rounded-circle h1">
                                <i class="ri-home-wifi-line"></i>
                            </div>
                        </div>
                        <h5>{{ $t('t-homepage-info-title1') }}</h5>
                        <p class="text-muted">{{ $t('t-homepage-info-memo1') }}</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="process-card">
                        <div class="process-arrow-img d-none d-lg-block">
                            <img src="@/assets/images/process-arrow-img.png" alt="" class="img-fluid">
                        </div>
                        <div class="avatar-sm icon-effect mx-auto mb-4">
                            <div class="avatar-title bg-transparent text-primary rounded-circle h1">
                                <i class="ri-team-line"></i>
                            </div>
                        </div>
                        <h5>{{ $t('t-homepage-info-title2') }}</h5>
                        <p class="text-muted">{{ $t('t-homepage-info-memo2') }}</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="process-card">
                        <div class="avatar-sm icon-effect mx-auto mb-4">
                            <div class="avatar-title bg-transparent text-primary rounded-circle h1">
                                <i class="ri-mic-line"></i>
                            </div>
                        </div>
                        <h5>{{ $t('t-homepage-info-title3') }}</h5>
                        <p class="text-muted">{{ $t('t-homepage-info-memo3') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>