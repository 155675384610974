<script>
import EpisodeRowItem from "../episodes/EpisodeRowItem.vue";

export default {
  data() {
    return {
    };
  },
  components: {
    EpisodeRowItem
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
};
</script>

<template>
  <div class="page-title-box d-sm-flex align-items-center justify-content-between">
    <h4 class="mb-sm-0">{{ $t('t-homepage-header-last-episodes') }}</h4>
  </div>
  <div class="team-list list-view-filter row">
    <div v-for="p in data.episodes" v-bind:key="p">
      <EpisodeRowItem v-bind:data="p"></EpisodeRowItem>
    </div>
  </div>
</template>
