<script>
import Layout from "../../layouts/main.vue";
import appConfig from "../../../app.config";

import axios from 'axios';
import Slider from "./Slider.vue";
import Banner from "./Banner.vue";
import Info from "./_Info.vue";
import TopPodcasts from "./_TopPodcasts.vue";
import TopProfiles from "./_TopProfiles.vue";
import LastEpisodes from "./_LastEpisodes.vue";
import PodcastsBanner from "./_PodcastsBanner.vue";
import PodcastsEpisodes from "./_PodcastsEpisodes.vue";

export default {
  page: {
    title: "Index",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Hello, world!",
      data: {
        profiles: [],
        podcasts: [],
        episodes: [],
      }
    };
  },
  components: {
    Layout,
    Slider,
    Banner,
    TopPodcasts,
    TopProfiles,
    LastEpisodes,
    PodcastsBanner,
    PodcastsEpisodes,
    Info,
  },
  created: function () {
    axios.get('/api/home/dashboard')
      .then((response) => {
        this.data = response.data;
      })
      .catch(function () {
      });
  },
};
</script>

<template>
  <Layout>
    <Banner></Banner>
    <Info></Info>
    <Slider v-bind:data="data" v-if="false"></Slider>
    <PodcastsBanner v-bind:data="data"></PodcastsBanner>
    <PodcastsEpisodes v-if="false"></PodcastsEpisodes>
    <TopPodcasts v-bind:data="data"></TopPodcasts>
    <TopProfiles v-bind:data="data"></TopProfiles>
    <LastEpisodes v-bind:data="data"></LastEpisodes>
  </Layout>
</template>